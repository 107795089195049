import React, { createContext, useMemo } from "react";
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { deDE } from "@mui/material/locale";

export const ThemeContext = createContext({});

const colors = {
  primary: {
    main: "#e40038",
  },
  secondary: {
    main: "#cb0031",
  },
  error: {
    main: "#f74437",
  },
  warning: {
    main: "#f59405",
  },
  info: {
    main: "#209afb",
  },
  success: {
    main: "#4ab14e",
  },
  divider: "rgba(0,0,0,0.28)",
  footer: {
    background: "#4F758B",
    color: "#FFFFFF",
  },
  header: {
    background: "#4F758B",
    color: "#FFFFFF",
    transparentBackground: true,
  },
  headerOnScroll: {
    background: "#FFFFFF",
    color: "#555",
  },
};

// eslint-disable-next-line react/prop-types
function ThemeProvider({ children }) {
  let themeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        xm: 992,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      type: "light",
      ...colors,
    },
    typography: {
      fontSize: 14,
      fontFamily: '"Source Sans Pro", "Helvetica", "Arial", "sans-serif"',
      fontWeightLight: 200,
      fontWeightRegular: 300,
      fontWeightMedium: 400,
      fontWeightBold: 500,
      h2: {
        fontFamily: '"Source Sans Pro", "Times New Roman", "serif"',
        fontSize: "3.2rem",
        fontWeight: 300,
        lineHeight: 1.25,
      },
      h3: {
        fontFamily: '"Source Sans Pro", "Times New Roman", "serif"',
        fontSize: "3rem",
        fontWeight: 300,
        lineHeight: 1.25,
      },
      h4: {
        fontFamily: '"Source Sans Pro", "Times New Roman", "serif"',
        fontSize: "2rem",
        fontWeight: 300,
        lineHeight: 1.25,
      },
      h6: {
        fontFamily: '"Source Sans Pro", "Times New Roman", "serif"',
        fontSize: "1.3rem",
        fontWeight: 400,
        lineHeight: 1.6,
      },
      subtitle2: {
        fontFamily: '"Source Sans Pro", "Helvetica", "Arial", "sans-serif"',
        fontSize: "0.9rem",
        fontWeight: 300,
        lineHeight: 1.5,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          h1: {
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "3.2rem",
          },
          h2: {
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "2.25rem",
          },
          h3: {
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "2rem",
          },
          h4: {
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "1.8rem",
          },
          h5: {
            fontWeight: "300",
            lineHeight: "1.5em",
            fontSize: "1.6rem",
            marginBlockStart: "1.5em",
            marginBlockEnd: "1.5em",
          },
          h6: {
            fontWeight: "200",
            lineHeight: "1.5em",
            fontSize: "1.4rem",
            marginBlockStart: "1.5em",
            marginBlockEnd: "1.5em",
          },
          //Override Style from Fullcalendar
          ".fc-scrollgrid-section-body": {
            cursor: "pointer",
          },
          ".fc-button-primary": {
            backgroundColor: colors.secondary.main + " !important",
            border: "none !important",
            color: "white !important",
          },
          ".fc-daygrid-event": {
            overflow: "hidden",
          },
          ":root": {
            "--fc-event-bg-color": colors.primary.main + " !important",
            "--fc-event-border-color": colors.primary.main + " !important",
          },
        },
      },
    },
    props: {
      MuiList: {
        dense: true,
      },
      MuiMenuItem: {
        dense: true,
      },
      MuiTable: {
        size: "small",
      },
      MuiTooltip: {
        arrow: true,
      },
    },
  };

  let theme = useMemo(() => createTheme(themeOptions, deDE), [themeOptions]);

  theme.typography.h1 = {
    fontFamily: '"Source Sans Pro", "Times New Roman", "serif"',
    fontSize: "3.5rem",
    fontWeight: 300,
    lineHeight: 1.25,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
    },
  };

  theme.typography.h5 = {
    fontFamily: '"Source Sans Pro","Helvetica","Arial",sans-serif',
    fontSize: "1.2rem",
    fontWeight: 300,
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  };

  theme.typography.subtitle1 = {
    fontFamily: '"Source Sans Pro", "Helvetica", "Arial", "sans-serif"',
    fontSize: "2rem",
    fontWeight: 300,
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  };

  return (
    <ThemeContext.Provider value={{}}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {/* eslint-disable-next-line react/prop-types */}
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
