import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom";
//import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import {
  InMemoryCache,
  ApolloProvider,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import "assets/css/index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { CircularProgress } from "@mui/material";
import ThemeProvider from "./provider/ThemeProvider";

//const container = document.getElementById("app");
//const root = createRoot(container);

// pages for this product
const LandingPage = lazy(() => import("views/LandingPage/LandingPage.js"));
const Page = lazy(() => import("views/Page/Page.js"));
const NewsPage = lazy(() => import("views/NewsPage/NewsPage.js"));
const DebugPage = lazy(() => import("views/DebugPage/DebugPage.js"));
const ComingSoonPage = lazy(() =>
  import("views/ComingSoonPage/ComingSoonPage.js")
);
const CancelReservation = lazy(() =>
  import("views/PredefinedPages/Plaetzle/CancelReservation")
);
const Songsheet = lazy(() => import("views/Apps/Songsheet"));
//const WGAufZeit = lazy(() => import("views/Apps/WGAufZeit"));
//const TeiliSection = lazy(() => import("views/Apps/WGAufZeit/TeiliSection"));
//const GuestSection = lazy(() => import("views/Apps/WGAufZeit/GuestSection"));
const TraineeSection = lazy(() => import("views/Apps/Trainee/TraineeSection"));

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_BACKEND_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line no-undef
  const token = process.env.REACT_APP_BACKEND_GRAPHQL_AUTH_TOKEN;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          launches: {
            keyArgs: false,
            merge(existing, incoming) {
              let launches = [];
              if (existing && existing.launches) {
                launches = launches.concat(existing.launches);
              }
              if (incoming && incoming.launches) {
                launches = launches.concat(incoming.launches);
              }
              return {
                ...incoming,
                launches,
              };
            },
          },
        },
      },
    },
  }),
  assumeImmutableResults: true,
});

const RedirectId = () => {
  const { id } = useParams();
  console.warn(
    `Die URL ${window.location.href} ist veraltet. Bitte speichere die neue URL ab.`
  );
  return <Navigate to={`/${id}`} replace />;
};

const RedirectToUrl = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);
  return (
    <h5 style={{ width: "100%", textAlign: "center" }}>
      Du wirst weitergeleitet...
    </h5>
  );
};
RedirectToUrl.propTypes = {
  url: PropTypes.string.isRequired,
};

//root.render(
ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className={"loadingCenterVerticalHorizontalBackground"}>
              <CircularProgress
                color="primary"
                className={"loadingCenterVerticalHorizontal"}
              />
            </div>
          }
        >
          <Routes>
            {/* Route Redirects */}
            <Route
              path="/administrator"
              element={<RedirectToUrl url={"https://app.hygraph.com/"} />}
            />
            <Route
              path="/admin"
              element={<RedirectToUrl url={"https://app.hygraph.com/"} />}
            />
            <Route
              path="/cookieAdministrator"
              element={<RedirectToUrl url={"https://my.osano.com/"} />}
            />
            <Route
              path="/cookies"
              element={<Navigate to={"/Datenschutz#cookies"} replace />}
            />
            {/* Hard-coded Routes */}
            <Route path="/debug" element={<DebugPage />} />
            <Route path="/comingsoon/:id" element={<ComingSoonPage />} />
            <Route
              path="/plaetzle/reservierung/:id"
              element={<CancelReservation />}
            />
            {/* App Routes */}
            {/*<Route path="/app/wgaufzeit24" element={<WGAufZeit />} />
            <Route path="/app/wgaufzeit24/gast" element={<GuestSection />} />
            <Route path="/app/wgaufzeit24/:id" element={<TeiliSection />} />*/}
            <Route path="/app/songsheet/:id" element={<Songsheet />} />
            <Route path="/app/trainee/:id" element={<TraineeSection />} />
            {/* redirect deprecated paths to the new route */}
            <Route path="/gruppen/:id" element={<RedirectId />} />
            <Route path="/aktionen/:id" element={<RedirectId />} />
            {/* Main Route */}
            <Route path="/news/:id" element={<NewsPage />} />
            <Route path="/:id" element={<Page />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to={"/"} replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
